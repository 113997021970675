import { history, logger, httpGet, httpPost } from '../../framework';

function createAction(result, type, additionalPayload) {
  logger.getLogger('Redux').info(result);
  let returnResult = {};
  if (result instanceof Error) {
    returnResult = { type, payload: Object.assign({ code: 'err.client.internSys', error: result.message }, additionalPayload) };
  } else if (result.result === 'err.server.timeOut') {
    history.push('/'); // redirect to login page after session timed out
    const resultPayload = { result: result.result, error: result.error, csrf: result.csrf };
    returnResult = { type, payload: resultPayload };
  } else {
    let resultPayload = { result: result.result, csrf: result.csrf };
    resultPayload = (result.error !== undefined) ? Object.assign(resultPayload, { error: result.error }) : resultPayload;
    resultPayload = (result.value !== undefined) ? Object.assign(resultPayload, { value: result.value }) : resultPayload;
    resultPayload = (additionalPayload !== undefined) ? Object.assign(resultPayload, additionalPayload) : resultPayload;
    returnResult = { type, payload: resultPayload };
  }
  logger.getLogger('Redux').info(returnResult);
  return returnResult;
}

function createErrorAction(result, type, additionalPayload) {
  const r = (typeof result === 'string') ? new Error(result) : result;
  return createAction(r, type, additionalPayload);
}

export function registerAction(values) {
  return async (dispatch) => {
    try {
      const response = await httpPost('/api/public/register', values);
      if (response.result === '0') {
        dispatch(createAction(response, 'REGISTER-SUCCESS'));
        history.push('/');
      } else {
        dispatch(createErrorAction(response, 'REGISTER-ERROR'));
      }
    } catch (err) {
      dispatch(createErrorAction(err, 'REGISTER-ERROR'));
    }
  };
}

export function loginAction(email, passwd) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'DATA-LOADING-BEGIN' });
      const response = await httpPost('/auth/login', { email, passwd });
      if (response.result === '0') {
        dispatch(createAction(response, 'LOGIN-SUCCESS'));
        history.push('/dashboard');
      } else {
        dispatch(createErrorAction(response, 'LOGIN-ERROR'));
      }
    } catch (err) {
      dispatch(createErrorAction(err, 'LOGIN-ERROR'));
    } finally {
      dispatch({ type: 'DATA-LOADING-END' });
    }
  };
}

export function logoutAction(csrf) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'DATA-LOADING-BEGIN' });
      const response = await httpPost('/auth/logout', { csrf });
      if (response.result === '0') {
        dispatch(createAction(response, 'LOGOUT-SUCCESS'));
        history.push(response.redirectURL);
      } else {
        dispatch(createErrorAction(response, 'LOGOUT-ERROR'));
      }
    } catch (err) {
      history.push('/');
    } finally {
      dispatch({ type: 'DATA-LOADING-END' });
    }
  };
}

export function authenticateAction() {
  return async (dispatch) => {
    try {
      dispatch({ type: 'DATA-LOADING-BEGIN' });
      const response = await httpGet('/auth/authenticate');
      if (response.result === '0') {
        dispatch(createAction(response, 'AUTHENTICATE-SUCCESS'));
      } else {
        dispatch(createErrorAction(response, 'AUTHENTICATE-ERROR'));
        history.push('/');
      }
    } catch (err) {
      history.push('/');
    } finally {
      dispatch({ type: 'DATA-LOADING-END' });
    }
  };
}

export function navigateAction(func) {
  return async (dispatch) => {
    const action = { type: 'NAVIGATE', payload: { to: func } };
    logger.getLogger('Redux').info(action);
    dispatch(action);
    if (!Array.isArray(func)) {
      history.push('/dashboard' + (func.backfuncName !== '' ? '/' + func.backfuncName + '/' : '/') + func.name);
    } else {
      history.push('/dashboard' + (func[0].backfuncName !== '' ? '/' + func[0].backfuncName : ''));
    }
  };
}

export function entityListAction(entityName, csrf) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'DATA-LOADING-BEGIN' });
      const response = await httpGet('/api/protected/data/list/' + entityName);
      if (response.result === '0') {
        dispatch(createAction(response, 'DATA-LIST-SUCCESS'));
      } else {
        dispatch(createErrorAction(response, 'DATA-LIST-ERROR'));
      }
    } catch (err) {
      dispatch(createErrorAction(err, 'DATA-LIST-ERROR'));
    } finally {
      dispatch({ type: 'DATA-LOADING-END' });
    }
  };
}

export function entityRefreshAction(donation_id, csrf) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'DATA-LOADING-BEGIN' });
      let enqResponse = await httpPost('/_api/enquireDonationPayment', {'donation_id':donation_id,'csrf':csrf});
      let response = {};
      //enqResponse.message
      response.result = enqResponse.result;
      response.value = enqResponse.respondmodel;
      response.csrf = csrf;
      if (response.result === 0) {
        var listResponse = await httpGet('/api/protected/data/list/aadodonation');
        if (listResponse.result === '0') {
          dispatch(createAction(listResponse, 'DATA-LIST-SUCCESS'));
          dispatch(createAction(response, 'DATA-REFRESH-SUCCESS'));
          let msgResponse = {"result":0,"message":{"status":"success"}};
          dispatch(createAction(msgResponse, 'REMOVE-MESSAGE'));
        } else {
          dispatch(createErrorAction(listResponse, 'DATA-LIST-ERROR'));
        }
      } else {
        //console.log('errMsg:'+JSON.stringify(enqResponse.errMsg));
        dispatch(createErrorAction(response, 'DATA-REFRESH-ERROR', { donation_id }));
      }
    } catch (err) {
      dispatch(createErrorAction(err, 'DATA-REFRESH-ERROR'));
    } finally {
      dispatch({ type: 'DATA-LOADING-END' });
    } 
  };
}

export function emailSendAction(donation_id, csrf) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'DATA-LOADING-BEGIN' });
      let response = await httpPost('/_api/postPrepareEmailandSendEmail', {'donation_id':donation_id,'csrf':csrf});
      if (response.result === 0) {
        dispatch(createAction(response, 'EMAIL-SEND-SUCCESS', { donation_id }));
        dispatch(createAction(response, 'REMOVE-MESSAGE'));
      } else {
        dispatch(createErrorAction(response, 'EMAIL-SEND-ERROR', { donation_id }));
      }
    } catch (err) {
      dispatch(createErrorAction(err, 'EMAIL-SEND-ERROR'));
    } finally {
      dispatch({ type: 'DATA-LOADING-END' });
    } 
  };
}

export function entityCreateAction(entityName, data, csrf) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'DATA-LOADING-BEGIN' });
      let response = await httpPost('/api/protected/data/create/' + entityName, { data, csrf });
      if (response.result === '0') {
        dispatch(createAction(response, 'DATA-CREATE-SUCCESS', { entityName }));
        response = await httpGet('/api/protected/data/list/' + entityName);
        if (response.result === '0') {
          dispatch(createAction(response, 'DATA-LIST-SUCCESS'));
        } else {
          dispatch(createErrorAction(response, 'DATA-LIST-ERROR'));
        }
      } else {
        dispatch(createErrorAction(response, 'DATA-CREATE-ERROR', { entityName }));
      }
    } catch (err) {
      dispatch(createErrorAction(err, 'DATA-CREATE-ERROR'));
    } finally {
      dispatch({ type: 'DATA-LOADING-END' });
    }
  };
}

export function entityUpdateAction(entityName, recordKey, data, csrf) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'DATA-LOADING-BEGIN' });
      let response = await httpPost('/api/protected/data/update/' + entityName, { key: recordKey, data, csrf });
      if (response.result === '0') {
        dispatch(createAction(response, 'DATA-UPDATE-SUCCESS', { entityName }));
        response = await httpGet('/api/protected/data/list/' + entityName);
        if (response.result === '0') {
          dispatch(createAction(response, 'DATA-LIST-SUCCESS'));
        } else {
          dispatch(createErrorAction(response, 'DATA-LIST-ERROR'));
        }
      } else {
        dispatch(createErrorAction(response, 'DATA-UPDATE-ERROR', { entityName }));
      }
    } catch (err) {
      dispatch(createErrorAction(err, 'DATA-UPDATE-ERROR'));
    } finally {
      dispatch({ type: 'DATA-LOADING-END' });
    }
  };
}

export function entityDeleteAction(entityName, recordKey, csrf) {
  return async (dispatch) => {
    try {
      dispatch({ type: 'DATA-LOADING-BEGIN' });
      let response = await httpPost('/api/protected/data/delete/' + entityName, { key: recordKey, csrf });
      if (response.result === '0') {
        dispatch(createAction(response, 'DATA-DELETE-SUCCESS', { entityName }));
        response = await httpGet('/api/protected/data/list/' + entityName);
        if (response.result === '0') {
          dispatch(createAction(response, 'DATA-LIST-SUCCESS'));
        } else {
          dispatch(createErrorAction(response, 'DATA-LIST-ERROR'));
        }
      } else {
        dispatch(createErrorAction(response, 'DATA-DELETE-ERROR', { entityName }));
      }
    } catch (err) {
      dispatch(createErrorAction(err, 'DATA-DELETE-ERROR'));
    } finally {
      dispatch({ type: 'DATA-LOADING-END' });
    }
  };
}
